// Navbar dropdowns
// based on http://javascript-array.com/scripts/jquery_simple_drop_down_menu/

const timeout = 300;
let closetimer = 0;
let ddmenutab = 0;
let ddmenuitem = 0;

const nav_open = function() {
  nav_canceltimer();
  nav_close();
  ddmenutab = $(this).addClass('open');
  return ddmenuitem = $(this).find('ul').eq(0).css('visibility', 'visible');
};

var nav_close = function() {
  if (ddmenutab) { ddmenutab.removeClass('open'); }
  if (ddmenuitem) { return ddmenuitem.css('visibility', 'hidden'); }
};

const nav_timer = () => closetimer = window.setTimeout(nav_close, timeout);

var nav_canceltimer = function() {
  if (closetimer) {
    window.clearTimeout(closetimer);
    return closetimer = null;
  }
};

$(document).on('turbolinks:load', function() {
  $('#navbar > ul > li').bind('mouseover', nav_open);
  return $('#navbar > ul > li').bind('mouseout', nav_timer);
});
  
document.onclick = nav_close;