// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";

Rails.start()
Turbolinks.start()
// ActiveStorage.start()

import "tether";
import "bootstrap";

import "../../src/admin/anchors";
import "../../src/admin/back_to_top";
import "../../src/admin/chosen";
import "../../src/admin/flatpickr";
import "../../src/admin/jinplace";
import "../../src/admin/navbar";
import "../../src/admin/tablesorter";
import "../../src/admin/tooltip";
