// ---
// Back to top
// based on https://codyhouse.co/gem/back-to-top/
// ---

$(document).on('turbolinks:load', function() {
  const scrollTopDuration = 300;
  const $backToTop = $('.back-to-top');

  // Smooth scroll to top
  $backToTop.on('click', function(event) {
    event.preventDefault();
    $('body,html').animate({ scrollTop: 0 }, scrollTopDuration);
  });
});