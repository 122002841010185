import 'chosen-js/chosen-sprite.png';
import 'chosen-js/chosen-sprite@2x.png';
import 'chosen-js/chosen';
import 'chosen-js/chosen.jquery';

$(document).on('turbolinks:load', function() {
  // Enable chosen JS
  $('.chosen-select').chosen({
    allow_single_deselect: true,
    no_results_text: 'No results matched',
    width: '100%',
    placeholder_text_single: ' ',
    placeholder_text_multiple: ' '
  });
});